<template>
    <div class="guru-guru">
        <div class="dots">
            <span style="--i:1"></span>
            <span style="--i:2"></span>
            <span style="--i:3"></span>
            <span style="--i:4"></span>
            <span style="--i:5"></span>
            <span style="--i:6"></span>
            <span style="--i:7"></span>
            <span style="--i:8"></span>
            <span style="--i:9"></span>
            <span style="--i:10"></span>
            <span style="--i:11"></span>
            <span style="--i:12"></span>
            <span style="--i:13"></span>
            <span style="--i:14"></span>
            <span style="--i:15"></span>
        </div>
    </div>
</template>

<script>
export default {
    mounted(){
        var URL = window.location.host
        var subdomain = window.location.host.split('.')
        var subdomainName = ''
        const domain = ['jaybod','jayent','jaybodenterprise']
        if(domain.includes(subdomain[1])){
            subdomainName = subdomain[0]
        } else {
            subdomainName = URL
        }
        // var subdomainName = 'eventmyeve'
        var payload = {
            domain: subdomainName
        }
        var token = this.$route.query

        this.$store.dispatch('enterprise/verifyToken', token)
        .then((res)=>{
            this.$store.dispatch('enterprise/getAppSettings', payload)
            .then(()=>{
                this.$router.push('/').catch(()=>{})
            })
        })
    }
}
</script>

<style>
.guru-guru{
    display: flex;
    flex: 1;
    background-color: #FFFFFF;
    align-items: center;
    justify-content: center;
}
.guru-guru .dots span{
    position: absolute;
    height: 10px;
    width: 10px;
    background: #00AAFF;
    border-radius: 50%;
    transform: rotate(calc(var(--i) * (360deg / 15))) translateY(35px);
    animation: animate 1.5s linear infinite;
    animation-delay: calc(var(--i) * 0.1s);
    opacity: 0;
}
@keyframes animate {
    0%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}
</style>